<template>
    <div>
        <el-container>
            <el-header style="height:100px">
                <div class="logo-box"></div>
                <ul class="listBox">
                    <li v-for="(item,i) in list" :key="i">
                        <span @click="itemClick(item,i)" :class="{'selected':n==i}">
                            {{item.name}}
                        </span>
                    </li>
                </ul>
            </el-header>
            <el-main><router-view></router-view></el-main>
        </el-container>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                list:[
                    {
                        name:"首页",
                        url:'Home'
                    },
                    {
                        name:"项目展示",
                        url:'ProjectDisplay'
                    },
                    {
                        name:"人才招聘",
                        url:'TalentRecruitment'
                    },
                    {
                        name:"联系我们",
                        url:'contactUs'
                    }
                ],
                n:0

            }
        },
        methods:{
            itemClick(item,i){
                this.n=i;
                this.$router.push(item.url)
            }
        }
        
    }
</script>
<style lang="less" scoped>
.el-header{
    background-color: #004d83;
}
.el-card__body, .el-main{
    padding: 10px;
}
.el-footer{
    background-color: #004d83;
}
.footer a{
    height:100px;
    line-height: 60px;
    color: #ffffff;
    text-align: center;
    display: block;
    text-decoration: none;
}

.content{
    width: 100%;
    height: 100%;
    // height:calc(100vh - 80px);
    // background-color: #EFEFEF;
    h1{
        text-align: center;
        margin: 0;
    }
}

.listBox{
    width:45%;
    float: right;
    padding:0;
    margin:0;
    li{
        line-height: 100px;
        list-style: none;
        float: left;
        color: white;
        font-size:22px;
        padding: 0 10px;
        cursor: pointer;
        text-align: center;
    }
    .selected{
        color:orange;
    }
}
.logo-box{
    width: 84px;
    height: 84px;
    margin: 8px 0 8px 80px;
    background: url('../assets/image/logo.jpg');
    background-size: 100% 100%;
    float: left;
}


</style>